



























































































































































































import { Manager } from '@/api/interfaces/manager'
import { newManager } from '@/components/molecules/select-search/store/manager'
import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'

const IRI_EN_COURS_CREATION = 'en_cours_creation'

export default defineComponent({
  components: {
    PhoneNumber: () => import('@/components/molecules/input/phone-number.vue'),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    company: {
      type: Object,
      required: false,
    },
    managerEdit: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      isSubmiting: false,
      manager: newManager() as Manager,
    }
  },
  computed: {
    companyName (): string {
      return this.company ? (this.company.name as string) : ''
    },
    isEditingManager (): boolean {
      return (this.manager['@id'] !== undefined && this.manager['@id'] !== '')
    },
  },
  created () {
    if (this.managerEdit && this.managerEdit['@id']) {
      (this.manager as Manager) = this.managerEdit as Manager
    } else {
      this.manager = newManager()
    }
  },
  watch: {
    managerEdit: {
      handler (newVal) {
        if (newVal && newVal['@id']) {
          (this.manager as Manager) = { ...this.managerEdit as Manager }
        } else {
          this.manager = newVal
        }
      },
    },
  },
  methods: {
    ...mapActions('managerList', {
      post: 'post',
      put: 'put',
    }),
    async onSubmit () {
      this.isSubmiting = true
      try {
        if (this.manager['@id'] && this.manager['@id'] !== IRI_EN_COURS_CREATION) {
          // case update manager when edit company
          await this.put(this.manager)
          this.$emit('managerUpdated', this.manager, this.managerEdit)
        } else {
          if (this.company && this.company['@id']) {
            // case create manager when edit company
            this.manager.company = this.company['@id']
            this.manager = await this.post(this.manager)
          }
          if (this.manager['@id'] !== IRI_EN_COURS_CREATION) {
            // case create when create company
            if (!this.manager['@id']) {
              this.manager['@id'] = IRI_EN_COURS_CREATION
            }
            this.$emit('managerCreated', this.manager)
          } else {
            // case edit when create company
            this.$emit('managerUpdated', this.manager, this.managerEdit)
          }
        }
      } finally {
        this.clear()
        this.$emit('close')
      }
    },
    clear () {
      this.manager = newManager()
      this.isSubmiting = false
      const observer = this.$refs.observer as InstanceType<
      typeof ValidationObserver
    >
      observer.reset()
    },
  },
})
