var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"900"},on:{"click:outside":function($event){return _vm.$emit('close')}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","inline":""}},[_c('template',{slot:"bottom"},[_c('span',{staticClass:" text-subtitle-1 pl-2"},[_vm._v(_vm._s(_vm.$t('form.required')))])]),_c('template',{slot:"title"},[_c('v-col',[_c('h2',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(((_vm.$t('trainee.form.formation.manager')) + " " + _vm.companyName))+" ")])])],1),_c('ValidationObserver',{ref:"observer",attrs:{"disabled":!_vm.dialog},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainee.form.formation.function'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{staticClass:"ml-3",attrs:{"error-messages":errors,"items":['Gestionnaire'],"outlined":"","label":_vm.$t('trainee.form.formation.function')},nativeOn:{"input":function($event){return (function (e) { return (_vm.manager.profession = e.target.value); })($event)}},model:{value:(_vm.manager.profession),callback:function ($$v) {_vm.$set(_vm.manager, "profession", $$v)},expression:"manager.profession"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainee.form.genre'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('v-select',{staticClass:"ml-3",attrs:{"error-messages":errors,"items":['M', 'Mme'],"outlined":"","label":_vm.$t('trainee.form.genre')},model:{value:(_vm.manager.civility),callback:function ($$v) {_vm.$set(_vm.manager, "civility", $$v)},expression:"manager.civility"}})}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainee.form.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('v-text-field',{directives:[{name:"uppercase",rawName:"v-uppercase"}],staticClass:"ml-3",attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('trainee.form.name') + '*'},model:{value:(_vm.manager.lastName),callback:function ($$v) {_vm.$set(_vm.manager, "lastName", $$v)},expression:"manager.lastName"}})}}],null,true)})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainee.form.firstName'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('v-text-field',{staticClass:"ml-3",attrs:{"error-messages":errors,"label":_vm.$t('trainee.form.firstName'),"outlined":""},model:{value:(_vm.manager.firstName),callback:function ($$v) {_vm.$set(_vm.manager, "firstName", $$v)},expression:"manager.firstName"}})}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('phone-number',{attrs:{"classes":"ml-3","label":_vm.$t('form.phone')},model:{value:(_vm.manager.phoneNumber),callback:function ($$v) {_vm.$set(_vm.manager, "phoneNumber", $$v)},expression:"manager.phoneNumber"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('phone-number',{attrs:{"classes":"ml-3","label":_vm.$t('form.secondPhone')},model:{value:(_vm.manager.secondPhoneNumber),callback:function ($$v) {_vm.$set(_vm.manager, "secondPhoneNumber", $$v)},expression:"manager.secondPhoneNumber"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainee.form.mail'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('v-text-field',{staticClass:"ml-3",attrs:{"error-messages":errors,"label":_vm.$t('trainee.form.mail'),"outlined":""},model:{value:(_vm.manager.email),callback:function ($$v) {_vm.$set(_vm.manager, "email", $$v)},expression:"manager.email"}})}}],null,true)})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"md":"3","sm":"4"}},[_c('v-btn',{attrs:{"elevation":"2","color":"primary","outlined":"","block":""},on:{"click":function($event){_vm.$emit('close')
                _vm.clear()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")])],1),(!_vm.isEditingManager)?_c('v-col',{attrs:{"md":"3","sm":"4"}},[_c('v-btn',{attrs:{"elevation":"2","color":"primary","outlined":"","block":""},on:{"click":function($event){return _vm.clear()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.clear'))+" ")])],1):_vm._e(),_c('v-col',{attrs:{"md":"3","sm":"4"}},[_c('v-btn',{attrs:{"elevation":"2","color":"primary","block":"","type":"submit","loading":_vm.isSubmiting}},[(_vm.isEditingManager)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('btn.save'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('btn.create'))+" ")])])],1)],1)],1)]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }